import React, { useState, useEffect } from "react";
import { Progress } from "@material-tailwind/react";

export function ProgressLabel() {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (progressValue !== 100) {
        setProgressValue((prevValue) => prevValue + 1);
      }
      //
    }, 10);

    return () => clearInterval(intervalId);
  }, [progressValue]);

  return <Progress value={progressValue} label="" />;
}
