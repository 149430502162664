import carIcon from "../assets/icons/car.png";
import limitIcon from "../assets/icons/limit.png";
import carAccidentIcon from "../assets/icons/car-accident.png";
import noAlcoholIcon from "../assets/icons/no-alcohol.png";
import targetIcon from "../assets/icons/target.png";
import CalculatorIcon from "../assets/icons/calculator.png";

import React, { createContext, useContext, useState } from "react";

const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const [openEmail, setOpenEmail] = useState(false);
  const [imageList, setImageList] = useState({
    liste: [
      {
        id: 1,
        titolo: "1. Rapporto Peso/Potenza",
        testo:
          "Uno dei concetti chiave per i neopatentati è il peso/potenza. Questo rapporto viene espresso in kW/tonnellata ed è un parametro critico per determinare quali veicoli puoi guidare. Se il rapporto peso/potenza del tuo veicolo è uguale o inferiore a 55 kW/tonnellata, allora puoi guidarlo senza restrizioni. Tuttavia, se supera questo valore, potresti essere soggetto a limitazioni.",
        immagine: CalculatorIcon,
      },
      {
        id: 2,
        titolo: "2. Limite di Potenza nel Primo Anno",
        testo:
          "Durante il tuo primo anno di guida dopo aver ottenuto la patente, in Italia c'è un limite di potenza. Questo limite è di solito di 70 kW, che corrisponde a circa 95 cavalli. Ciò significa che non puoi guidare veicoli con una potenza superiore a questo valore nel tuo primo anno di guida, indipendentemente dal rapporto peso/potenza.",
        immagine: limitIcon,
      },
      {
        id: 3,
        titolo: "3. Acquisto di un'Auto Usata",
        testo:
          "Per i neopatentati, può essere conveniente considerare l'acquisto di un'auto usata all'inizio della loro esperienza di guida. Le auto usate possono essere più economiche e possono rappresentare una scelta saggia per acquisire esperienza senza dover gestire un veicolo nuovo. Assicurati di effettuare una verifica accurata del veicolo usato prima dell'acquisto e considera l'opzione di un'auto che rientra nei parametri del rapporto peso/potenza.",
        immagine: carIcon,
      },
      {
        id: 4,
        titolo: "4. Guida Sicura e Responsabile",
        testo:
          "Durante i primi tre anni di neopatentato, vengono assegnati punti doppi per le infrazioni commesse su tutte le strade, che includono strade urbane, extraurbane, autostrade e superstrade. Ciò significa che una semplice violazione delle norme stradali potrebbe comportare la perdita di un numero maggiore di punti dalla tua patente.",
        immagine: carAccidentIcon,
      },
      {
        id: 5,
        titolo: "5. Limiti di Alcol e Limitazioni per i 3 Anni di Neopatentato",
        testo:
          "Per i neopatentati in Italia, è fondamentale essere a conoscenza dei limiti di alcol consentiti. Il limite di alcol nel sangue (BAC) per i neopatentati è generalmente inferiore rispetto a conducenti esperti. Durante i primi 3 anni di neopatentato, il limite di alcol nel sangue di solito è di 0,0 g/dl, il che significa che non è consentito alcun consumo di alcol prima di mettersi al volante.",
        immagine: noAlcoholIcon,
      },
      {
        id: 6,
        titolo: "Conclusione",
        testo:
          "Ottenere la patente di guida è un grande passo nella vita di ogni individuo, ma per i neopatentati in Italia, è importante essere consapevoli delle regole e delle restrizioni che si applicano. Rispettando queste norme, puoi godere di una guida sicura e responsabile mentre accumuli esperienza come conducente.",
        immagine: targetIcon,
      },
    ],
  });
  return (
    <ImageContext.Provider value={[{ imageList }, { openEmail, setOpenEmail }]}>
      {children}
    </ImageContext.Provider>
  );
};

export const useImage = () => {
  return useContext(ImageContext);
};
