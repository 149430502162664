import { Typography } from "@material-tailwind/react";

const Introduzione = ({ targetRef_Intro }) => {
  return (
    <div className="flex flex-col p-10 justify-center " name="introduzione">
      <Typography
        variant={"h3"}
        className={"mb-2 sm:mb-3 lg:mb-4 "}
        ref={targetRef_Intro}
      >
        Introduzione
      </Typography>
      <Typography variant={"paragraph"} className={"mb-1 md:mb-2 lg:mb-4"}>
        Ottenere la patente di guida è un importante traguardo, ma per i
        neopatentati in Italia, ci sono alcune regole e restrizioni importanti
        da tenere a mente. Questo articolo fornisce una guida completa su ciò
        che devi sapere per guidare in modo sicuro e responsabile nei primi anni
        dopo aver ottenuto la patente.
      </Typography>
    </div>
  );
};

export default Introduzione;
