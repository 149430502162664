import { Typography } from "@material-tailwind/react";
const Card = ({ titolo, testo, immagine }) => {
  return (
    <div className="flex flex-col pr-10 pl-10 pt-10 lg:pb-10 text-center" name="titolo">
      <div className="flex justify-center items-center mb-4 md:6 lg:mb-8 rounded-full">
        <img
          src={immagine}
          alt={titolo}
          className="object-cover w-[80px] md:w-[96px] lg:w-[144px] "
        />
      </div>
      <Typography variant={"h5"} className={"mb-1 md:mb-2 lg:mb-4 "}>
        {titolo}
      </Typography>
      <Typography variant={"paragraph"} className={"mb-1 md:mb-2 lg:mb-4 "}>
        {testo}
      </Typography>
    </div>
  );
};

export default Card;
