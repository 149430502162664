import carImage from "../assets/img/transform.svg";
import { gsap } from "gsap";
import { useEffect } from "react";
import CSSPlugin from "gsap/CSSPlugin";

const Animazione = ({ comp }) => {
  const C = CSSPlugin;
  const t1 = gsap.timeline();
  useEffect(() => {
    let ctx = gsap.context(() => {
      t1.from("#car", {
        xPercent: "130",
        duration: 1.3,
        delay: 0.3,
      });
    }, comp);
    return () => ctx.revert();
  }, [comp]);

  return (
    <img
      id="car"
      src={carImage}
      alt="Car"
      className="object-cover rounded lg:w-[22rem] lg:h-[22rem] md:w-[20rem] md:h-[20rem] w-[12rem] h-[12rem]"
    />
  );
};

export default Animazione;
