import React, { useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Textarea,
} from "@material-tailwind/react";
import { useImage } from "../components/ImageContext.jsx";
import { ProgressLabel } from "./ProgressLabel.jsx";

const EmailForm = () => {
  const [{}, { openEmail, setOpenEmail }] = useImage();

  const [progress, setProgress] = useState(false);

  const handleOpen = () => {
    setOpenEmail(() => !openEmail);
    if (openEmail) {
      setProgress(false);
    }
  };

  const onSubmit = async (event) => {
    setProgress(() => true);
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "aed306b7-c630-4dae-a0fe-71959c0208bb");

    const object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });

    if (object.nome && object.cognome && object.email && object.motivazione) {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(object),
      })
        .then((res) => {
          res.json();
          handleOpen();
        })
        .catch((err) => console.log("Error", err)); //da togliere questo commento
    }
  };

  return (
    <>
      <Dialog open={openEmail} handler={handleOpen} className="flex bg-transparent shadow-none">
        <Card className="flex m-auto w-full lg:max-w-[32rem] ">
          <form onSubmit={onSubmit}>
            <CardBody className="flex flex-col gap-1">
              <Typography variant="h4" color="blue-gray">
                Contattaci
              </Typography>
              <Typography
                className="mb-2 font-normal"
                variant="paragraph"
                color="gray"
              >
                Inserisci i tuoi dati ed il perché desideri contattarci
              </Typography>
              <Typography className="mb-1" variant="h6">
                Nome
              </Typography>
              <Input
                size="lg"
                name="nome"
                placeholder="Nome"
                required
                className={`text-left ${
                  window.innerWidth <= 768 ? "pl-10" : "lg:pl-6"
                }`}
              />
              <Typography className="mb-1" variant="h6">
                Cognome
              </Typography>
              <Input
                size="lg"
                name="cognome"
                required
                placeholder="Cognome"
                className={`text-left ${
                  window.innerWidth <= 768 ? "pl-10" : "lg:pl-6"
                }`}
              />
              <Typography className="mb-1" variant="h6">
                Email
              </Typography>
              <Input
                size="lg"
                type="email"
                name="email"
                required
                placeholder="Email"
                className={`text-left ${
                  window.innerWidth <= 768 ? "pl-10" : "lg:pl-6"
                }`}
              />
              <Typography className="mb-1" variant="h6">
                Motivazione
              </Typography>
              <Textarea
                size="lg"
                name="motivazione"
                required
                placeholder="Motivazione"
                className={`text-left ${
                  window.innerWidth <= 768 ? "pl-10" : "lg:pl-6"
                }`}
              />
            </CardBody>
            <CardFooter className="pt-0">
              <Button
                variant="gradient"
                fullWidth
                type="submit"
                disabled={progress}
              >
                Invia
              </Button>
            </CardFooter>
          </form>
          {progress ? <ProgressLabel></ProgressLabel> : null}
        </Card>
      </Dialog>
    </>
  );
};

export default EmailForm;
