import Animazione from "./Animazione";
import CarouselCustomArrows from "./Carosello";
import Introduzione from "./Introduzione";
import Limiti from "./Limiti";
import { Typography, Button } from "@material-tailwind/react";
import {useEffect, useRef, useState} from "react";

const Title = ({
  handleCalcolatoreClick,
  isScreenSmall,
  targetRef_Intro,
  targetRef_Limiti,
}) => {
  const refAnimation = useRef(null);

  const onClickCalcolo = () => {
    handleCalcolatoreClick();
  };


  const [isScreenPhone, setIsScreenPhone] = useState(
      window.matchMedia("(max-width: 426px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 426px)");

    const handleMediaQueryChange = (e) => {
      setIsScreenPhone(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);


  return (
    <div className="flex flex-col " name="principaleTesti">
      <div className="lg:grid lg:grid-cols-2 " name="introduzione">
        <div
          className="flex flex-col p-10 lg:pb-[100px] justify-center "
          name="titolo"
        >
          <Typography
            variant={"h2"}
            className={
              "text-red-600 mb-2 sm:mb-4 lg:mb-6 lg:text-5xl"
            }
          >
            Guida per Neopatentati in Italia: tutto quello che devi sapere
          </Typography>
          <Typography
            variant={"lead"}
            className={"mb-1 md:mb-2 lg:mb-4 "}
          >
            Se sei un appassionato di automobili, un pilota, o semplicemente
            desideri capire meglio il rapporto tra il peso del tuo veicolo e la
            sua potenza, sei nel posto giusto! Il nostro sito è la risorsa
            ideale per calcolare e comprendere questo aspetto fondamentale delle
            prestazioni automobilistiche.
          </Typography>
        </div>
        <div
          className="p-10 flex flex-col justify-center items-center "
          name="fotoSkip"
          ref={refAnimation}
        >
          <Animazione comp={refAnimation} />
          <div className="flex justify-center items-center lg:mt-0 mt-5">
            <Button
              variant="outlined"
              className="flex items-center gap-2"
              onClick={onClickCalcolo}
            >
              Vai al calcolatore{" "}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>15.Arrow Down15.Arrow Down</title>
                <g id="_15.Arrow_Down" data-name="15.Arrow Down">
                  <path d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Z" />
                  <polygon points="12 18.414 7.293 13.707 8.707 12.293 12 15.586 15.293 12.293 16.707 13.707 12 18.414" />
                  <rect x="11" y="6" width="2" height="11" />
                </g>
              </svg>
            </Button>
          </div>
        </div>
        <div
          className={`flex flex-col justify-center items-center lg:p-10 ${
            isScreenSmall ? "hidden" : "none"
          }`}
          name="fotoIntroduzione"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 80 80"
            className="lg:w-[300px] lg:h-[300px] xl:w-[300px] xl:h-[300px]"
            style={{ width: "60%" }}
          >
            <path
              fill="#FFCC43"
              d="M7.032 56.006a6.6 6.6 0 0 0 6.6 6.6h52.8a6.6 6.6 0 0 0 6.6-6.6v-32.45a6.6 6.6 0 0 0-6.6-6.6h-52.8a6.6 6.6 0 0 0-6.6 6.6v32.45z"
            />
            <path
              fill="#667BBC"
              d="M48.282 54.956h16.75v-16.75h-16.75v16.75zm-41.25-32v9.75h65.25v-9.75H7.032z"
            />
            <path
              fill="#131731"
              d="M64.551 15.123H15.254c-5.462 0-9.889 4.427-9.891 9.891v29.719c.002 5.461 4.429 9.888 9.891 9.89h49.297c5.464-.002 9.891-4.429 9.891-9.89h.002v-29.72a9.891 9.891 0 0 0-9.893-9.89zm-49.297 2.999h49.297a6.899 6.899 0 0 1 6.62 5H8.637a6.895 6.895 0 0 1 6.617-5zm56.189 36.611a6.9 6.9 0 0 1-6.892 6.889H15.254a6.9 6.9 0 0 1-6.891-6.889l.002-20.61h63.078v20.61zm0-23.611H8.365v-5h63.078v5zm-5.08 6.25H47.115v19.25h19.248v-19.25zm-2.999 16.25H50.113V40.371h13.251v13.251zm-33.833-8.5H13.615v3.001h15.916v-3.001zm0 7.001H13.615v3h15.916v-3z"
            />
          </svg>
        </div>
        <Introduzione targetRef_Intro={targetRef_Intro} />
      </div>

      {(isScreenPhone) ? (
        <CarouselCustomArrows targetRef_Limiti={targetRef_Limiti}/>
      ) : (
        <Limiti targetRef_Limiti={targetRef_Limiti} />
      )}
    </div>
  );
};

export default Title;
