import React from "react";
import "./App.css";
import { Home } from "./pages/Home";
import { ImageProvider } from "./components/ImageContext";

const App = () => {
  return (
    <ImageProvider>
      <Home></Home>
    </ImageProvider>
  );
};

export default App;
