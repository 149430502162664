import { useImage } from "../components/ImageContext.jsx";
import Card from "./Card.jsx";

const Limiti = ({ targetRef_Limiti }) => {
  const [{ imageList }] = useImage();

  return (
    <div
      ref={targetRef_Limiti}
      className="lg:grid lg:grid-cols-3 lg:gap-4 lg:pt-16 md:grid md:grid-cols-2 md:gap-2 "
      name="regole"
    >
      {imageList &&
        imageList.liste.map((item) => (
          <Card
            key={item.id}
            titolo={item.titolo}
            testo={item.testo}
            immagine={item.immagine}
          />
        ))}
    </div>
  );
};

export default Limiti;
