import carImage from "../assets/img/dacia-duster-neopatentati.jpg";
import { Button, Input, Switch } from "@material-tailwind/react";
import { useState, useEffect } from "react";

const Calcolatore = ({ targetRef_Arrow }) => {
  const [weight, setWeight] = useState("");
  const [power, setPower] = useState("");
  const [result, setResult] = useState("");
  const [conversionKW, setConversionKW] = useState(false);
  const [sentence, setSentence] = useState("");
  const [sentence2, setSentence2] = useState("");

  useEffect(() => {
    if (conversionKW) {
      setSentence("(CV)");
      setSentence2("Utilizza cavalli (CV)");
    } else {
      setSentence("(KW)");
      setSentence2("Utilizza KW");
    }
  }, [conversionKW]);

  let totWeight = 0;
  let totValue = 0;
  const calculateRatio = () => {
    let weightValue = parseFloat(weight);
    let powerValue = parseFloat(power);

    if (isNaN(weightValue) || isNaN(powerValue)) {
      setResult("Inserisci valori validi per peso e potenza.");
      return;
    }

    if (conversionKW) {
      totWeight = (weightValue + 75) / 1000;
      powerValue = powerValue * 0.7354;
      totValue = powerValue / totWeight;
    } else {
      totWeight = (weightValue + 75) / 1000;
      totValue = powerValue / totWeight;
    }

    if (totValue <= 55.0 && powerValue <= 70) {
      setResult(
        `Puoi guidare l'auto! Il rapporto peso/potenza è ${totValue.toFixed(2)}`
      );
    } else {
      setResult("Non puoi guidare l'auto come neopatentato.");
    }
  };

  return (
    <div
      ref={targetRef_Arrow}
      className={`flex flex-col lg:w-full lg:p-4 md:w-[300px] md:ml-0 bg-white rounded-lg shadow-lg lg:mb-12 md:mb-10 mb-6 p-2 w-[250px]`}
    >
      <h1 className="text-red-500 font-bold m-2 pt-1 ms:mb-4 lg:mb-6 text-sm md:text-lg lg:text-3xl">
        Calcolatore auto per neopatentati!
      </h1>
      <div className="mb-2 lg:mb-2">
        <div className="m-2 lg:mb-2.5">
          <Input
            type="text"
            label="Peso auto (Kg)"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            className="mb-1 lg:mb-2"
          />
        </div>
        <div className="m-2 lg:mb-2.5">
          <Input
            type="text"
            label={`Potenza auto ${sentence}`}
            value={power}
            onChange={(e) => setPower(e.target.value)}
            className="md:mb-2"
          />
        </div>
        <div className="row-auto flex items-center ml-3 mb-0">
          <Switch onChange={(e) => setConversionKW(e.target.checked)} />
          <span className="ml-2">{sentence2}</span>
        </div>
      </div>
      <div className="flex items-center justify-center ml-2 mr-2">
        <Button color="red" onClick={calculateRatio} className="w-full">
          Calcola
        </Button>
      </div>
      <div className="flex items-center justify-center mt-2 ml-2 mr-2">
        <p className="font-semibold text-sm md:text-lg lg:text-xl">{result}</p>
      </div>
    </div>
  );
};

export default Calcolatore;
