import { Carousel, IconButton } from "@material-tailwind/react";
import { useImage } from "../components/ImageContext.jsx";
import Card from "./Card.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
const CarouselCustomArrows = ({ targetRef_Limiti }) => {
  const [{ imageList }] = useImage();

  return (
      <Carousel
          ref={targetRef_Limiti}
          className="rounded-xl pb-3"
          prevArrow={({ handlePrev }) => (
              <IconButton
                  variant="text"
                  size="xs"
                  onClick={handlePrev}
                  className="!absolute top-2/4 left-0 -translate-y-2/4"
              >
                  <FontAwesomeIcon icon={faChevronLeft} className="text-current h-4" />
              </IconButton>
          )}
          nextArrow={({ handleNext }) => (
              <IconButton
                  variant="text"
                  size="xs"
                  onClick={handleNext}
                  className="!absolute top-2/4 !right-0 -translate-y-2/4"
              >
                  <FontAwesomeIcon icon={faChevronRight} className="text-current h-4" />
              </IconButton>
          )}
          navigation={({ setActiveIndex, activeIndex, length }) => (
              <div className=" absolute bottom-0 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                {new Array(length).fill("").map((_, i) => (
                    <span
                        key={i}
                        className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                            activeIndex === i ? "w-8 bg-gray-500" : "w-4 bg-gray-500/50"
                        }`}
                        onClick={() => setActiveIndex(i)}
                    />
                ))}
              </div>
          )}
      >
      {imageList &&
        imageList.liste.map((item) => (
          <Card
            key={item.id}
            titolo={item.titolo}
            testo={item.testo}
            immagine={item.immagine}
          />
        ))}
    </Carousel>
  );
};

export default CarouselCustomArrows;
