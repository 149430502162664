const Pubblicita = () => {
  return (
    <div
      className="flex col-auto lg:justify-end md:justify-end "
      name="pubblicità_2"
    >

    </div>
  );
};

export default Pubblicita;
