import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useImage } from "../components/ImageContext.jsx";

export function NavbarSimple({
  handleIntroClick,
  handleLimitiClick,
  handleCalcolatoreClick,
}) {
  const [openNav, setOpenNav] = React.useState(false);

  const onClickIntro = async () => {
    const result = await handleIntroClick();
    if (result) {
      setOpenNav(false);
    }
  };

  const onClickLimiti = async () => {
    const result = await handleLimitiClick();
    if (result) {
      setOpenNav(false);
    }
  };

  const onClickCalcolatore = async () => {
    const result = await handleCalcolatoreClick();
    if (result) {
      setOpenNav(false);
    }
  };

  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [{}, { openEmail, setOpenEmail }] = useImage();
  const handleOpen = () => {
    setOpenNav(false);
    setOpenEmail(!openEmail);
  };

  return (
    <Navbar className="fixed top-0 mx-auto max-w-full px-6 py-3 rounded-none z-[99999]">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="/"
          variant="h5"
          className="mr-4 cursor-pointer py-1.5"
        >
          Infoneopatentati.it
        </Typography>
        <div className="hidden lg:block">
          <ul className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            <Typography
              as="li"
              variant="small"
              color="blue-gray"
              className="p-1 font-medium"
            >
              <button
                className="flex items-center hover:text-blue-500 transition-colors"
                onClick={onClickIntro}
              >
                Introduzione
              </button>
            </Typography>
            <Typography
              as="li"
              variant="small"
              color="blue-gray"
              className="p-1 font-medium"
            >
              <button
                onClick={onClickLimiti}
                className="flex items-center hover:text-blue-500 transition-colors"
              >
                Limitazioni
              </button>
            </Typography>
            <Typography
              as="li"
              variant="small"
              color="blue-gray"
              className="p-1 font-medium"
            >
              <button
                className="flex items-center hover:text-blue-500 transition-colors"
                onClick={onClickCalcolatore}
              >
                Calcolatore
              </button>
            </Typography>
            <Typography
              as="li"
              variant="small"
              color="blue-gray"
              className="p-1 font-medium"
            >
              <button
                className="flex items-center hover:text-blue-500 transition-colors"
                onClick={handleOpen}
              >
                Contact us
              </button>
            </Typography>
          </ul>
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <ul className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
          <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-medium"
            onClick={onClickIntro}
          >
            <button className="flex items-center hover:text-blue-500 transition-colors">
              Introduzione
            </button>
          </Typography>
          <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-medium"
            onClick={onClickLimiti}
          >
            <button
              onClick={onClickLimiti}
              className="flex items-center hover:text-blue-500 transition-colors"
            >
              Limitazioni
            </button>
          </Typography>
          <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-medium"
            onClick={onClickCalcolatore}
          >
            <button
              className="flex items-center hover:text-blue-500 transition-colors"
              onClick={onClickCalcolatore}
            >
              Calcolatore
            </button>
          </Typography>
          <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-medium"
          >
            <a
              className="flex items-center hover:text-blue-500 transition-colors"
              onClick={handleOpen}
            >
              Contact us
            </a>
          </Typography>
        </ul>
      </Collapse>
    </Navbar>
  );
}
