import { Typography } from "@material-tailwind/react";
import { useImage } from "../components/ImageContext.jsx";
import EmailForm from "./EmailForm.jsx";

export function SimpleFooter() {
  const [{}, { openEmail, setOpenEmail }] = useImage();
  const handleOpen = () => {
    setOpenEmail(!openEmail);
  };

  return (
    <footer className="bg-gray-300 flex w-full lg:flex-row md:flex-row lg:flex-wrap md:flex-wrap flex-col items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-50 py-6 text-center md:justify-between pl-5 pr-5 mt-10 lg:pl-[140px] lg:pr-[115px]">
      <EmailForm/>
      <Typography color="blue-gray" className="font-normal">
        &copy; 2023 Infoneopatentati.it
      </Typography>
      <ul className="flex flex-wrap items-center justify-center gap-y-2 gap-x-8">
        <li>
          <Typography
            color="blue-gray"
            className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500 cursor-pointer"
            onClick={handleOpen}
          >
            Contact us
          </Typography>
        </li>
        <li>
          <Typography
            color="blue-gray"
            className="font-normal transition-colors focus:text-blue-500 "
          >
            Made by{" "}
            <a
              className="hover:text-blue-500 font-light italic"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/giovanni-ercolano"
              onClick={() => console.log("King Mobile")}
            >
              Giovanni Ercolano
            </a>{" "}
            &{" "}
            <a
              className="hover:text-blue-500 font-light italic"
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/Galitom"
            >
              Tommaso Galimberti
            </a>
          </Typography>
        </li>
      </ul>
    </footer>
  );
}
