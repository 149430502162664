import { SimpleFooter } from "../components/Footer";
import React, { useEffect, useRef, useState } from "react";
import { NavbarSimple } from "../components/Navbar";
import Title from "../components/Title.jsx";
import Calcolatore from "../components/Calcolatore.jsx";
import Cookie from "../components/Cookie.jsx";
import Pubblicita from "../components/Pubblicita.jsx";
import CalcolatoreNuovo from "../components/CalcolatoreNuovo.jsx";

export const Home = () => {
  const [isScreenSmall, setIsScreenSmall] = useState(
    window.matchMedia("(max-width: 820px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 820px)");

    const handleMediaQueryChange = (e) => {
      setIsScreenSmall(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const targetRef_Arrow = useRef(null);
  const targetRef_Intro = useRef(null);
  const targetRef_Limiti = useRef(null);
  const [scrollToTarget, setScrollToTarget] = useState(false);

  const handleCalcolatoreClick = async () => {
    if (targetRef_Arrow.current) {
      const targetElement = targetRef_Arrow.current;
      const targetElementPosition = targetElement.getBoundingClientRect();

      const yOffset = targetElementPosition.top;

      setScrollToTarget(yOffset - 80);

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 700);
      });
    }
    return Promise.resolve(true);
  };

  const handleIntroClick = async () => {
    if (targetRef_Intro.current) {
      const targetElement = targetRef_Intro.current;
      const targetElementPosition = targetElement.getBoundingClientRect();

      const yOffset = targetElementPosition.top;

      if (isScreenSmall) {
        setScrollToTarget(yOffset - 80);
      } else {
        setScrollToTarget(yOffset - 220);
      }
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 700);
      });
    }
    return Promise.resolve(true);
  };

  const handleLimitiClick = async () => {
    if (targetRef_Limiti.current) {
      const targetElement = targetRef_Limiti.current;
      const targetElementPosition = targetElement.getBoundingClientRect();
      const yOffset = targetElementPosition.top;

      setScrollToTarget(yOffset - 30);

      // Restituisci una promessa che si risolverà quando tutte le operazioni sono completate
      return new Promise((resolve) => {
        // Simulazione di un'operazione asincrona, ad esempio una chiamata API
        setTimeout(() => {
          resolve(true);
        }, 600); // Modifica il timeout in base alle tue esigenze
      });
    }
    // Se non ci sono operazioni da eseguire, restituisci subito true
    return Promise.resolve(true);
  };

  if (scrollToTarget !== false) {
    window.scrollTo({
      top: window.scrollY + scrollToTarget,
      behavior: "smooth",
    });

    setScrollToTarget(false);
  }


    const [isLandscape, setIsLandscape] = useState(false);

    useEffect(() => {
      const handleOrientationChange = () => {
        const isCurrentlyLandscape = window.matchMedia('(orientation: landscape)').matches;
        setIsLandscape(isCurrentlyLandscape);
      };

      // Aggiungi un ascoltatore per l'evento di cambio orientamento
      window.addEventListener('orientationchange', handleOrientationChange);

      // Controlla l'orientamento iniziale
      handleOrientationChange();

      // Pulisci l'ascoltatore quando il componente si smonta
      return () => {
        window.removeEventListener('orientationchange', handleOrientationChange);
      };
    }, []);


  return (
      <div className={` bg-gray-200 ${(isScreenSmall) ? "mt-10" : "mt-15"}`}>
      <div className={`flex justify-center  ${isLandscape ? "mb-10" : "lg:mb-8"} `}>
        <NavbarSimple
            handleIntroClick={handleIntroClick}
            handleLimitiClick={handleLimitiClick}
            handleCalcolatoreClick={handleCalcolatoreClick}      />
      </div>
      <Title
        handleCalcolatoreClick={handleCalcolatoreClick}
        isScreenSmall={isScreenSmall}
        targetRef_Intro={targetRef_Intro}
        targetRef_Limiti={targetRef_Limiti}
      />

      <div
        className="lg:gap-4 lg:mt-16 md:mt-10 mt-10 md:flex md:flex-row md:gap-2 flex flex-col justify-center items-center"
        name="calcolatrice"
      >
        <Pubblicita />
        {(isScreenSmall)?<Calcolatore targetRef_Arrow={targetRef_Arrow}/> : <CalcolatoreNuovo targetRef_Arrow={targetRef_Arrow} />}
        <Pubblicita />
      </div>
      <SimpleFooter/>
    </div>
  );
};
